import HttpProxy from '@/services/HttpProxy';
import store from '@/store';

export default class BusinessService {
  static async savePrintConfig(data) {
    const response = await HttpProxy.submit({
      method: 'post',
      url: 'Business/PrintConfiguration',
      data,
    });
  
    if (!response?.data) {
      return null;
    }
    
    store.dispatch('invoices/update', { config: data.invoices });
    return response.data;
  }

  static async saveDateFormat(data) {
    await HttpProxy.submit({
      method: 'put',
      url: 'BusinessInfo/DateFormat',
      data,
    });

    store.dispatch('business/update', {
      ...store.state.business,
      dateFormat: data.dateFormat,
    });
  }
}
