<template>
  <div class="w-full xl:px-36 sm:px-8">
    <!-- POLICY -->
    <div>
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>&nbsp;Politicas y plantillas</strong>
        </h1>
        <div class="ml-auto">
          <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="savePolicy">
            {{ saveButtonLabel }}
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card v-loading.fullscreen.lock="loading"
          title="Aquí puedes llenar las políticas de garantía que saldrán en tus tickets para que el cliente las lea y firme.">
          <sa-editor :content="policy" @update:content="policy = $event" />
        </content-card>
      </div>
    </div>

    <!-- CHECKLIST -->
    <div v-if="showChecklistConfig">
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>&nbsp;Checklist de Revisión</strong>
        </h1>
        <div class="ml-auto">
          <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="saveChecklistItems">
            {{ saveButtonLabel }}
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card v-loading.fullscreen.lock="loading">
          <div slot="title" class="w-full flex flex-row" style="justify-content: space-between">
            <div class="flex">Elementos del Checklist de Revisión</div>
            <div class="flex">
              <a v-if="!addItem" class="text-md text-purple-400" @click="addItem = true">
                <strong>+ Agregar nuevo elemento</strong>
              </a>
            </div>
          </div>
          <div v-if="addItem" class="w-full mb-4">
            <div class="w-full flex flex-row">
              <el-input v-model="value.name" />
              <div class="flex flex-row text-center ml-2" style="align-items: center">
                <div class="w-1/2">
                  <button title="Guardar" class="el-button--primary rounded-full cursor-pointer mr-4" @click="createChecklistItem">
                    <i class="bx bx-save" style="padding: 4px; font-size: 20px"></i>
                  </button>
                </div>
                <div class="w-1/2">
                  <button title="Eliminar" class="el-button--danger rounded-full cursor-pointer" @click="
                    addItem = false;
                  value = { id: null, name: null };
                  ">
                    <i class="bx bx-x" style="padding: 4px; font-size: 20px"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="py-2">
            <div class="block w-full overflow-x-auto">
              <table class="items-center w-full border-collapse">
                <thead>
                  <tr class="px-2 border-b-2">
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap w-1/12"></th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap w-1/12">
                      #
                    </th>
                    <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap w-10/12">
                      Nombre
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in $store.state.checklistItems.entities" :key="item.id"
                    class="hover:bg-gray-100 hover:shadow">
                    <td class="p-2 border-t w-1/12">
                      <div class="flex flex-row text-center">
                        <div class="w-1/2">
                          <button title="Editar" class="el-button--primary rounded-full cursor-pointer mr-4" @click="
                            addItem = true;
                          value = { id: item.id, name: item.name };
                          ">
                            <i class="bx bx-pencil" style="padding: 4px; font-size: 20px"></i>
                          </button>
                        </div>
                        <div class="w-1/2">
                          <button title="Eliminar" class="el-button--danger rounded-full cursor-pointer"
                            @click="onDeleteChecklist(item)">
                            <i class="bx bx-trash" style="padding: 4px; font-size: 20px"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td class="p-2 border-t w-1/12">{{ index + 1 }}</td>
                    <td class="p-2 border-t w-10/12">{{ item.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </content-card>
      </div>
    </div>

    <!-- PRINT -->
    <div v-if="showPrintConfig">
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>&nbsp;Facturas/Impresiones</strong>
        </h1>
        <div class="ml-auto">
          <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="onPrintCongSave">
            {{ saveButtonLabel }}
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card v-loading.fullscreen.lock="loading" title="Configuración de impresiones">
          <print-config-form title="Carta" :type="PrintTypes.Letter" :config="printConfigs[PrintTypes.Letter]"
            @config:update="onPrintConfigsChange" />
          <div class="-mx-5 border-t border-gray-200" />

          <print-config-form title="Térmico" :type="PrintTypes.Thermal" :config="printConfigs[PrintTypes.Thermal]"
            @config:update="onPrintConfigsChange" />
          <div class="-mx-5 border-t border-gray-200" />

          <print-config-form :disabled="{ logo: true, businessName: true }" title="Sticker" :type="PrintTypes.Sticker"
            :config="printConfigs[PrintTypes.Sticker]" @config:update="onPrintConfigsChange" />

        </content-card>
      </div>
    </div>

    <div v-if="showOrderStatusConfig" ref="orderStatusConfigRef">
      <div class="flex flex-row items-center py-4">
        <h1 class="text-lg font-bold inline-flex items-center px-3 py-3">
          <strong>&nbsp;Estatus de Ordenes</strong>
        </h1>
        <div class="ml-auto">
          <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="onOrderStatusCreate">
            Crear
          </sa-button>
        </div>
      </div>
      <div class="flex flex-col space-y-4">
        <content-card v-loading.fullscreen.lock="loading" title="Configuración de estatus de ordenes">
          <table class="text-left border border-collapse border-slate-500 border-separate border-spacing-2">
            <thead>
              <tr>
                <th class="border border-slate-600"></th>
                <th class="border border-slate-600 text-center">#</th>
                <th class="border border-slate-600">Nombre</th>
                <th class="border border-slate-600">Color</th>
                <th class="border border-slate-600">Activo</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="status in statuses" :key="status.id">
                <td class="border border-slate-600 w-24">
                  <sa-group-action-button
                    :disableEdit="!status.isEditable || !status.isActive"
                    :disableDelete="!status.isDeletable || !status.isActive"
                    @edit="onOrderStatusEdit(status)"
                    @delete="onOrderStatusDelete(status)"></sa-group-action-button>
                </td>
                <td class="border border-slate-600 text-center">{{ status.order }}</td>
                <td class="border border-slate-600">{{ status.name }}</td>
                <td class="border border-slate-600 text-center">
                  <div class='mt-2 text-xl rounded-sm badge' :style="{ 'background-color': status.color }" />
                </td>
                <td class="border border-slate-600 text-center">
                  <el-switch
                    :value="status.isActive"
                    @change="onOrderStatusActiveChange($event, status)"
                    :disabled="!status.isEditable" />
                </td>
              </tr>
            </tbody>
          </table>
        </content-card>
      </div>
    </div>
    <el-dialog :visible.sync="visible">
      <order-status-form :status="status" @save="onOrderStatusSave($event)"></order-status-form>
    </el-dialog>
  </div>
</template>
<style scoped>
.badge {
  margin: 8px 4px;
  height: 16px;
}
</style>
<script>
import _ from "lodash";
import error from "@/mixins/error";
import AdminService from "@/services/AdminService";
import ChecklistItemsService from "@/services/ChecklistItemsService";
import { PrintTypes, DefaultPrintConfiguration } from '@/constants';
import BusinessService from '@/services/BusinessService';
import CatalogService from '@/services/CatalogService';

export default {
  name: "PoliciesAndTemplatesForm",
  mixins: [error],
  props: {
    saveButtonLabel: {
      type: String,
      default: () => "Guardar",
    },
    callback: {
      type: Function,
      default: () => { },
    },
    showChecklistConfig: {
      type: Boolean,
      default: () => false,
    },
    showPrintConfig: {
      type: Boolean,
      default: () => false,
    },
    showOrderStatusConfig: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    ContentCard: () => import("@/components/molecules/ContentCard"),
    PrintConfigForm: () => import("@/components/forms/PrintConfigForm"),
    OrderStatusForm: () => import("@/components/forms/OrderStatusForm"),
  },
  data() {
    return {
      loading: false,
      errorMessage: undefined,
      policy: null,
      addItem: false,
      value: {
        id: null,
        name: null,
      },
      PrintTypes,
      printConfigs: {
        [PrintTypes.Letter]: this.$store.state.invoices.config[PrintTypes.Letter]
          || DefaultPrintConfiguration,
        [PrintTypes.Thermal]: this.$store.state.invoices.config[PrintTypes.Thermal]
          || DefaultPrintConfiguration,
        [PrintTypes.Sticker]: this.$store.state.invoices.config[PrintTypes.Sticker]
          || { ...DefaultPrintConfiguration, showLogo: false, showBusinessName: false },
      },
      statuses: this.$store.state.catalogs.orderStatuses,
      visible: false,
      status: {
        order: 0,
        name: '',
        color: '#000000',
      },
    };
  },
  mounted() {
    this.loadPolicy();
    if (this.$route.params.scrollToElementInSlot) {
      setTimeout(
        () => {
          this.scrollToElement(this.$route.params.scrollToElementInSlot);
        }, 100);
    }
  },
  watch: {
    errorMessage: {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300),
    },
  },
  methods: {
    scrollToElement(refName) {
      const container = this.$refs[refName];
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },

    // POLICY
    async loadPolicy() {
      try {
        const { policy } = await AdminService.PoliciesAndTemplates.read();
        this.policy = policy ?? null;
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      }
    },
    async savePolicy() {
      this.loading = true;
      try {
        const data = new FormData();
        data.append("policy", this.policy);
        await AdminService.PoliciesAndTemplates.update(data);
        this.$toastr.s("Se actualizo con exito");
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
        if (this.callback && typeof this.callback === "function")
          this.callback();
      }
    },

    // CHECKLIST
    onDeleteChecklist(item) {
      this.$confirm(`¿Está seguro que desea eliminar este elemento?`, {
        type: "warning",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
      }).then(async () => {
        await ChecklistItemsService.delete(item.id);
        this.$toastr.s("Se borro con exito");
      });
    },
    async saveChecklistItems() {
      this.$toastr.s("Se actualizo con exito");
    },
    async createChecklistItem() {
      this.loading = true;
      try {
        if (this.value.id) await ChecklistItemsService.update(this.value);
        else await ChecklistItemsService.create({ name: this.value.name });
        this.addItem = false;
        this.value = { id: null, name: null };
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },

    // PRINT CONFIG
    onPrintConfigsChange({ key, value }) {
      this.printConfigs = {
        ...this.printConfigs,
        [key]: value,
      };
    },
    async onPrintCongSave() {
      this.loading = true;
      try {
        const data = {
          businessId: this.$store.state.account.businessId,
          invoices: Object.keys(this.printConfigs).reduce((acc, key) => {
            const printConfig = this.printConfigs[key];
            return {
              ...acc,
              [key]: {
                id: printConfig.id || undefined,
                logoWidth: printConfig.logoX || '100',
                logoHeight: printConfig.logoY || '100',
                canViewLogo: printConfig.showLogo || false,
                canViewBusinessName: printConfig.showBusinessName || false,
                canViewPasscode: printConfig.showPasscode || false,
              },
            };
          }, {}),
        };
        await BusinessService.savePrintConfig(data);
        this.$toastr.s('Se actualizo con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },

    // ORDER STATUS CONFIG
    onOrderStatusCreate() {
      this.status = {
        // get max status order and add 1 to get next order
        order: parseInt(this.statuses
          .reduce((acc, curr) => curr.order > acc.order
            ? curr : acc, this.statuses[0])
          .order, 10) + 1,
        name: '',
        color: '#000000',
      };
      this.visible = true;
    },
    onOrderStatusEdit(status) {
      this.visible = true;
      this.status = { ...status };
    }, 
    onOrderStatusSave(status) {
      if (status.id) {
        if (!status.isEditable || !status.isActive) {
          this.$toastr.e('Este estatus no es editable');
          return;
        }
        this.updateOrderStatus(status);
      } else {
        this.createOrderStatus(status);
      }
    },
    onOrderStatusDelete(status) {
      if (!status.isDeletable || !status.isActive) {
        this.$toastr.e('Este estatus no es eliminable');
        return;
      }

      this.$confirm(`¿Está seguro que desea eliminar este estatus?`, {
        type: 'warning',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
      }).then(async () => {
        await this.deleteOrderStatus(status.id);
      })
    },
    onOrderStatusActiveChange(isActive, status) {
      if (!status.isEditable) {
        this.$toastr.e('Este estatus no es editable');
        return;
      }
      this.$confirm(
        `¿Está seguro que desea ${isActive ? 'activar' : 'desactivar'} el estatus "${status.name}"?`,
      ).then(async () => {
        await this.updateOrderStatus({
          ...status, isActive: isActive,
        });
      });
    },
    async createOrderStatus(status) {
      this.loading = true;
      try {
        const { id } = await CatalogService.postOrderStatus({
          name: status.name,
          colour: status.color,
          order: status.order,
        });
        this.statuses = [
          ...this.statuses,
          {
            id,
            name: status.name,
            color: status.color,
            order: status.order,
            isActive: true,
            isEditable: true,
            isDeletable: true,
          },
        ].sort((a, b) => a.order - b.order);
        this.$toastr.s('Se creó con exito');
        this.visible = false;
        this.status = {
          id: null,
          name: '',
          color: '#000000',
          order: 0,
        };
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },
    async updateOrderStatus(status) {
      this.loading = true;
      try {
        CatalogService.putOrderStatus({
          id: status.id,
          name: status.name,
          colour: status.color,
          order: status.order,
          isActive: status.isActive,
        });
        this.statuses = this.statuses
          .map((s) => (s.id === status.id ? ({
            id: status.id,
            name: status.name,
            color: status.color,
            order: status.order,
            isActive: status.isActive,
            isEditable: s.isEditable,
            isDeletable: s.isDeletable,
          }) : s))
          .sort((a, b) => a.order - b.order);
        this.$toastr.s('Se actualizó con exito');
        this.visible = false;
        this.status = {
          id: null,
          name: '',
          color: '#000000',
          order: 0,
        };
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteOrderStatus(statusId) {
      this.loading = true;
      try {
        await CatalogService.deleteOrderStatus(statusId);
        this.statuses = this.statuses
          .filter((status) => status.id !== statusId)
          .sort((a, b) => a.order - b.order);
        this.$toastr.s('Se eliminó con exito');
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
